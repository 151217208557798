<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


import { useTaskApi } from "@/apis/task";

/**
 * Advanced table component
 */
export default {
  page: {
    title: '任务详情',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    

  },
  validations() {
    return {
     
    }
  },

  data() {
    return {
      tableData: [],
      listingData: [],
     
      title: this.$t('produce.list_page.sub_title'),
      items: [
        {
          text: this.$t('produce.list_page.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('produce.list_page.nav_second_item'),
          href: "/produce/task_list",
        },
        {
          text: "任务详情",
          active: true,
        },
      ],

      totalRows: 1,
      perPage: 50,
      currentPage: 1,


      t_list_fields: [
        { key: 'TaskNumber', label: '子任务编号' },
        { key: 'Dept', label: '负责班组' },
        { key: 'Status', label: '任务状态' },
        { key: 'FinishDate', label: '完成日期' },
      ],
      p_list_fields: [
        { key: 'Code', label: this.$t('produce.new_task.list_table_field.code') },
        { key: 'PNumber', label: this.$t('produce.new_task.list_table_field.pnumber') },
        { key: 'Dept', label: '班组' },
        { key: 'Leader', label: '责任人' },
        { key: 'PCount', label: '投产数量' },
        { key: 'StockCount', label: '入库数量' },
        { key: 'Portion', label: '完成度' },
        { key: 'Pos', label: '操作' },

      ],

      task: {
        fields : {},
        produce_list : [],
      },
      

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },


  },
  mounted() {

    this.query_task(this.$route.query.parent_task);
  
  },

  methods: {

    query_task(task_number) {
      useTaskApi().task_detail(task_number).then(res => {
        if (res.err == 0) {
          this.task = res.data

          this.listingData = []
          res.data.produce_list.map(e =>{
            this.listingData.push({
              code: e.model_code,
              dept: e.dept_name,
              pnumber: e.model_number,
              pcount: e.p_count,
              scount: e.o_count,
              persons : e.persons,
              fields : e.fields
            })
          })
        }
      })
    },


    get_person(item, key) {
     
      if (item.persons) {
        let p = item.persons.find(e => e.key == key)
        return p?p.user_name  : 'N/A'
      } else {
        return 'N/A'
      }
    },


    confirm_finish_task() {
      this.$alertify.confirmWithTitle('确认',
        "确定要标记此此任务结束吗？",
        () => {
          useTaskApi().finish_task(this.task.task_number, { remarks: this.task.remarks }).then(res => {
            if (res.err == 0) {
              this.task.task_status  = 'DONE'
              this.$alertify.message("标记成功");
              this.$bvModal.hide('finish_task_dialog')
            } else {
              this.$alertify.error("标记失败 " + res.err + ":" + res.msg);
            }

          })
        },
        () => {

        }
      );
    },


  }


};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal id="finish_task_dialog" title="投产任务" size="lg" centered hide-footer :static="true">
      <div class="row mb-3">
        <div class="col-md-8">
          <label class="form-label">说明</label>
          <input type="text" class="form-control" v-model="task.remarks" maxlength="100" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
            <button type="button" class="btn btn-secondary w-sm" @click="$bvModal.hide('finish_task_dialog')"> 取消
            </button>
            <button type="submit" class="btn btn-primary w-sm" @click="confirm_finish_task"> 确认
            </button>
          </div>
        </div>
      </div>

    </b-modal>

    <div class="row">
      <div class="col-12">
        <div class="card">

          <div class="card-body">

            <div class="card border shadow-none mb-3">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-6">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                          </div>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="card-title">投产任务基本信息</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                      <button type="button" class="btn btn-secondary w-sm"
                        @click="$router.push({ name: 'produce-task-list' })">返回</button>
                      <button type="button" :disabled="task.task_status != 'SUBMITTED'" class="btn btn-primary w-sm" @click="$bvModal.show('finish_task_dialog')"> 投产结束</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <label class="form-label">任务编号</label>
                    <div class="mb-3">
                      <input class="form-control" v-model="task.task_number" readonly />
                    </div>

                  </div>
                  <div class="col-md-2">
                    <label class="form-label">项目</label>
                    <input class="form-control" v-model="task.fields.project" readonly />
                  </div>

                  <div class="col-md-2">
                    <label class="form-label">预计完成时间</label>
                    <input class="form-control" v-model="task.fields.plan_date" readonly />
                  </div>

                  <div class="col-md-4">
                    <label class="form-label">说明</label>
                    <input class="form-control" v-model="task.fields.remarks" readonly />

                  </div>

                </div>

              </div>
            </div>
            <!-- end section 1 -->


            <b-tabs>

              <b-tab title="投产清单">
                <!-- Table -->
                <div class="table-responsive mb-0" style="min-height: 60vh;">
                  <b-table :items="listingData" :fields="p_list_fields" responsive="sm" :per-page="listingData.length"
                    class="table-check">
                    <template #cell(Code)="data">
                      {{ data.item.code }}
                    </template>
                    <template #cell(PNumber)="data">
                      {{ data.item.pnumber }}
                    </template>

                    <template #cell(Dept)="data">
                      {{ data.item.dept }}
                    </template>
                    <template #cell(Leader)="data">
                      {{ get_person(data.item, 'responser') }}
                    </template>
                    <template #cell(PCount)="data">
                      {{ data.item.pcount }}
                    </template>
                    <template #cell(StockCount)="data">
                      {{ data.item.scount }}
                    </template>


                    <template #cell(Portion)="data">
                      {{ Number(data.item.scount / data.item.pcount * 100).toFixed(0) + '%' }}
                    </template>


                    <template #cell(Pos)="data">
                      <b-dropdown variant="white" toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                        </template>
                        <li>
                          <b-dropdown-item @click="$router.push({'name': 'produce-model-detail', query : {task_number : task.task_number, model: data.item.code}})"><i
                              class="mdi mdi-pencil font-size-16 text-success me-1"></i> 明细
                          </b-dropdown-item>
                        </li>


                      </b-dropdown>
                    </template>



                  </b-table>
                </div>
              </b-tab>
            </b-tabs>

          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
